import React from 'react'
import styled from '@emotion/styled'
import { lighten } from 'polished'

import {
  AlphaColors, Colors, Gutter, Transitions
} from '../../../utils/styles'

import Checkbox from './Checkbox'
import './formStyles.css'

const FormContainer = styled.div({
  marginTop: Gutter.FULL,
})

const InputContainerText = styled.label({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: Gutter.FULL,
})

const NameContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  [InputContainerText]: {
    width: '50%',
    ':first-child': {
      marginRight: Gutter.FULL,
    },
    '@media (max-width: 520px)': {
      width: '100%',
    },
  },
  '@media (max-width: 520px)': {
    flexDirection: 'column',
  },
})

const FormLabel = styled.div({
  fontSize: 15,
  fontWeight: 600,
  marginBottom: Gutter.QUARTER,
})

const inputFontSize = 18
const inputPlaceholderFontSize = 14
const inputPlaceholderColor = AlphaColors.BLACK_60

const FormInputText = styled.input({
  border: '1px solid',
  borderColor: 'transparent',
  borderRadius: 4,
  color: Colors.BLACK,
  fontSize: inputFontSize,
  padding: 8,
  transition: Transitions.NORMAL,
  transitionProperty: 'border-color, box-shadow',
  ':hover': {
    borderColor: AlphaColors.PRIMARY_50,
  },
  ':focus': {
    borderColor: Colors.PRIMARY,
    boxShadow: '0 0 16px 0 rgba(6,86,217,0.30)',
    outline: 'none',
  },
  '::placeholder': {
    color: inputPlaceholderColor,
    fontSize: inputPlaceholderFontSize,
  },
})

const FormTextArea = styled.textarea({
  border: '1px solid',
  borderColor: 'transparent',
  borderRadius: 4,
  color: Colors.BLACK,
  fontSize: inputFontSize,
  minHeight: 148,
  padding: 8,
  transition: Transitions.NORMAL,
  transitionProperty: 'border-color, box-shadow',
  ':hover': {
    borderColor: AlphaColors.PRIMARY_50,
  },
  ':focus': {
    borderColor: Colors.PRIMARY,
    boxShadow: '0 0 16px 0 rgba(6,86,217,0.30)',
    outline: 'none',
  },
  '::placeholder': {
    color: inputPlaceholderColor,
    fontSize: inputPlaceholderFontSize,
  },
})

const CheckboxContainer = styled.div({
  marginTop: Gutter.FULL,
  marginBottom: Gutter.DOUBLE,
  [FormLabel]: {
    marginBottom: Gutter.HALF,
  },
})

const StyledCheckbox = styled(Checkbox)({
  marginBottom: Gutter.HALF,
})

const SubmitButton = styled.input({
  backgroundColor: Colors.PRIMARY,
  borderRadius: 4,
  border: 'none',
  color: Colors.WHITE,
  cursor: 'pointer',
  fontSize: 14,
  fontWeight: 700,
  marginTop: Gutter.HALF,
  padding: `12px ${Gutter.FULL}`,
  transition: Transitions.NORMAL,
  transitionProperty: 'background-color, box-shadow',
  ':hover': {
    backgroundColor: lighten(0.1, Colors.PRIMARY),
    boxShadow: '0 0 16px 0 rgba(6,86,217,0.30)',
  },
})

const RequestDemoForm = () => (
  <FormContainer>
    <form action="https://formspree.io/chuck.flores@dedhamgroup.com" method="POST">
      <NameContainer>
        {/* First Name Field */}
        <InputContainerText htmlFor="request-demo-form-firstName">
          <FormLabel>First Name</FormLabel>
          <FormInputText
            type="text"
            id="request-demo-form-firstName"
            placeholder="Your First Name"
            required
            name="firstName"
          />
        </InputContainerText>
        {/* Last Name Field */}
        <InputContainerText htmlFor="request-demo-form-lastName">
          <FormLabel>Last Name</FormLabel>
          <FormInputText
            type="text"
            id="request-demo-form-lastName"
            placeholder="Your Last Name"
            required
            name="lastName"
          />
        </InputContainerText>
      </NameContainer>
      {/* Company Field */}
      <InputContainerText htmlFor="request-demo-form-company">
        <FormLabel>Company</FormLabel>
        <FormInputText
          type="text"
          id="request-demo-form-company"
          placeholder="Your Company"
          required
          name="company"
        />
      </InputContainerText>
      {/* Title or Role Field */}
      <InputContainerText htmlFor="request-demo-form-title">
        <FormLabel>Title/Role</FormLabel>
        <FormInputText
          type="text"
          id="request-demo-form-title"
          placeholder="Your Title/Role within company"
          required
          name="role"
        />
      </InputContainerText>
      {/* Email Field */}
      <InputContainerText htmlFor="request-demo-form-email">
        <FormLabel>Email</FormLabel>
        <FormInputText
          type="text"
          id="request-demo-form-email"
          placeholder="email@company.com"
          required
          name="_replyto"
        />
      </InputContainerText>
      {/* Tool Checkboxes */}
      <CheckboxContainer>
        <FormLabel>What tool(s) are you interested in? (optional)</FormLabel>
        <StyledCheckbox
          id="request-demo-form-payers"
          name="payerTool"
          label="Payer Quality of Access"
        />
        <StyledCheckbox
          id="request-demo-form-valueBasedModels"
          name="valueBasedModelsTool"
          label="Oncology Pathways & Alternative Payment Models"
        />
        <StyledCheckbox
          id="request-demo-form-providers"
          name="providerTool"
          label="Provider Key Accounts"
        />
      </CheckboxContainer>
      {/* Email Field */}
      <InputContainerText htmlFor="message">
        <FormLabel>Message (optional)</FormLabel>
        <FormTextArea
          type="text"
          id="request-demo-form-message"
          placeholder="Anything else we should know?"
          name="message"
        />
      </InputContainerText>
      <input type="hidden" name="_next" value="https://www.pulseanalyticsapp.com/thank-you" />
      <SubmitButton type="submit" value="Submit" />
    </form>
  </FormContainer>
)

export default RequestDemoForm
