import React from 'react'

import SEO from '../components/seo'

import LayoutRequestDemo from '../pageLayouts/LayoutRequestDemo'

const RequestDemoPage = () => (
  <div>
    <SEO
      title="Data Analytics and Decision Support Platform for Market Access"
      keywords={[
        'pulse digital',
        'healthcare',
        'react',
        'software engineering',
        'new york',
        'nyc',
        'pulse',
        'javascript',
        'analytics',
        'development',
      ]}
    />
    <LayoutRequestDemo />
  </div>
)

export default RequestDemoPage
