import React from 'react'
import { Flex } from '@rebass/grid/emotion'
import styled from '@emotion/styled'

import { H2, Paragraph } from '../../components/Typography'
import { Colors, Gutter, MediaQueries } from '../../utils/styles'
import assets from '../../content/assets'

const StyledFlex = styled(Flex)`
  @media (max-width: ${MediaQueries.TABLET}) {
    display: none;
  }
`

const DisplayContentContainer = styled.div({
  display: 'none',
  paddingTop: Gutter.DOUBLE,
  textAlign: 'center',
  width: '100%',
})

const Heading = styled(H2)({
  color: Colors.WHITE,
  fontSize: 24,
  fontWeight: 400,
})

const TextItem = styled(Paragraph)({
  color: Colors.WHITE,
})

const FormDisplaySection = () => (
  <StyledFlex
    width={[1, 1, 1, 1 / 2]}
    p={[Gutter.FULL, Gutter.FULL, Gutter.FULL, Gutter.DOUBLE]}
    style={{
      backgroundImage: `url(${assets.images.fluidBubblesPrimary4})`,
      backgroundSize: 'cover',
    }}
  >
    <DisplayContentContainer>
      <Heading>Services & Tools That Enable You To Do More With Less…</Heading>
      <TextItem>Relevant Metrics, Optimal Update Frequency, & Practical Insights</TextItem>
      <TextItem>Multi-Stakeholder Validation for Accurate, Reliable Data</TextItem>
      <TextItem>Intuitive Interface & Functionality for On-The-Fly Strategic Thinking</TextItem>
      <TextItem>Pharma/Biotech Workflow-Minded Design For Ease of Integration</TextItem>
    </DisplayContentContainer>
  </StyledFlex>
)

export default FormDisplaySection
