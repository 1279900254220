import React from 'react'
import { Flex } from '@rebass/grid/emotion'

import Layout from '../../components/Layout'
import FormSection from './FormSection'
import FormDisplaySection from './FormDisplaySection'

const LayoutRequestDemo = () => (
  <Layout>
    <Flex flexWrap="wrap">
      <FormSection />
      <FormDisplaySection />
    </Flex>
  </Layout>
)

export default LayoutRequestDemo
