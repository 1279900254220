import React from 'react'
import { Flex } from '@rebass/grid/emotion'
import styled from '@emotion/styled'

import { H1, Paragraph } from '../../components/Typography'
import { Colors, Gutter, Heights } from '../../utils/styles'
import RequestDemoForm from './RequestDemoForm'

const StyledFlex = styled(Flex)`
  background: ${Colors.LIGHT_BLUE_GRAY};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) : {
    margin-top: ${Heights.PRIMARY_NAV_BAR};
  }
`

const FormSection = () => (
  <StyledFlex
    width={[1, 1, 1, 1 / 2]}
    p={[Gutter.FULL, Gutter.FULL, Gutter.DOUBLE]}
    flexDirection="column"
  >
    <div>
      <H1 style={{ fontWeight: 500 }}>Schedule a Demo</H1>
      <Paragraph>
        We are more than happy to walk you through the Pulse Analytics platform and discuss how it
        can benefit your team. Just fill out the form below:
      </Paragraph>
    </div>
    <RequestDemoForm />
  </StyledFlex>
)

export default FormSection
