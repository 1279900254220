import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Gutter } from '../../../utils/styles'

import './formStyles.css'

const CheckboxLabel = styled.label({
  display: 'flex',
  alignItems: 'center',
  marginBottom: Gutter.HALF,
})

const CheckboxText = styled.span({
  fontSize: 16,
  fontWeight: 400,
  marginLeft: Gutter.HALF,
})

const Checkbox = props => (
  <CheckboxLabel htmlFor={props.id} className="label-cbx">
    <input id={props.id} type="checkbox" className="invisible" name={props.name} />
    <div className="checkbox">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 20 20">
        {/* eslint-disable-next-line */}
        <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z" />
        <polyline points="4 11 8 15 16 6" />
      </svg>
    </div>
    <CheckboxText>{props.label}</CheckboxText>
  </CheckboxLabel>
)

export default Checkbox

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
